import API from './api'
import axios from 'axios'

export const LOGIN = async (state: any) => {
    const loginSend = {
        "username": state.username,
        "password": state.password,
        "ipUser": state.ipUser
    }
    try{
        const response = await axios.post(`${API.API_URL}${API.LOGIN}`, loginSend)
        return response.data;
    }
    catch(error){
        return error.response.data
    }
};