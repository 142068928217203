import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { LOGIN } from "../../../api/user"
import i18n from '@/plugins/i18n'
import { GET_PROFILE_AGENT } from '../../../api/account';

Vue.use(VuejsDatatableFactory);

@Component({
    computed: {
        lang: {
            get: function () {
                return this.$store.state.locale
            }
        }
    }

})
default class Login extends Vue {
    private dataLogin: any = [];
    private showPassword = false;

    public changeLanguage(val: string) {
        this.$store.dispatch('changeLocale', val)
        i18n.locale = val
    }

    public loginChangeInput(key: string, value: string) {
        this.dataLogin[key] = value
    }

    public async getIPuser() {
        const ipData = await (await fetch('https://api.ipify.org?format=json')).json()
        this.dataLogin['ipUser'] = ipData.ip
    }

    public async loginButton(event: any) {
        event.preventDefault();
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        await this.getIPuser()
        const login = await LOGIN(this.dataLogin)

        
        if (login.success) {
            this.$cookies.set('login', login.data);
            const profile = await GET_PROFILE_AGENT(login.data.username);
            this.$store.dispatch("addProfile", profile[0]);
            
            window.location.replace('/');
            loader.hide()

        } else {
            const isHttpError = typeof login.statusCode != 'undefined'
            if(isHttpError){
                this.$swal({
                    text: 'You send too many request, please wait a minute.',
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                });
            }
            else{
                if(login.error.code == '405'){
                    this.$swal({
                        text: `${this.$t('LOGIN.ACCOUNT_LOCK').toString()}`,
                        icon: "error",
                        confirmButtonColor: '#CFA137',
                    });
                } else
                {
                    this.$swal({
                        text: `${this.$t('LOGIN.WRONG_PASSWORD').toString()}`,
                        icon: "error",
                        confirmButtonColor: '#CFA137',
                    });
                }
            }
            loader.hide()
            return
        }
    }

    public toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    public sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
export default Login