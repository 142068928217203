














































































































import { Component, Mixins } from "vue-property-decorator";
import LoginComponent from "./Login.component";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faKey,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

library.add(faUser, faKey, faEye, faEyeSlash);

@Component
export default class Home extends Mixins(LoginComponent) {}
