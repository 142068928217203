var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-wrapper" }, [
    _c("div", {}, [
      _c(
        "form",
        {
          staticClass: "login-section",
          on: {
            submit: function($event) {
              return _vm.loginButton($event)
            }
          }
        },
        [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass: "login-footer py-2 justify-content-center",
                attrs: { "data-v-273d7304": "" }
              },
              [
                _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle",
                      attrs: {
                        href: "",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.lang == "th-TH"
                              ? "img/flag/th.png"
                              : "img/flag/en.png",
                          alt: "",
                          width: "20",
                          height: "20"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "ml-2",
                          staticStyle: { color: "white", "font-weight": "bold" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lang == "th-TH"
                                ? _vm.$t("LANG.TH")
                                : _vm.$t("LANG.EN")
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "dropdown-menu" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            return _vm.changeLanguage("th-TH")
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "img/flag/th.png",
                            alt: "",
                            width: "20",
                            height: "20"
                          }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("LANG.TH")))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "dropdown-divider" }),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            return _vm.changeLanguage("en-EN")
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "img/flag/en.png",
                            alt: "",
                            width: "20",
                            height: "20"
                          }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("LANG.EN")))
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "dropdown-divider" })
                  ])
                ])
              ]
            ),
            _c("hr", {
              staticClass: "m-0 d-none d-lg-block",
              staticStyle: { height: "1px", "background-color": "white" }
            }),
            _vm._m(0),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-body-holder" }, [
                _c("div", { staticClass: "form-group row mb-4" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "md-form md-outline" }, [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputUserName",
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.loginChangeInput(
                              "username",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "username" } },
                        [_vm._v(_vm._s(_vm.$t("LOGIN.USERNAME")))]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "form-group row mb-4" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "md-form md-outline" }, [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: _vm.showPassword ? "text" : "password",
                          id: "inputPassword",
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.loginChangeInput(
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "password" } },
                        [_vm._v(_vm._s(_vm.$t("LOGIN.PASSWORD")))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "toggle-password field-icon",
                          attrs: { toggle: "#password" }
                        },
                        [
                          _c("i", {
                            staticClass: "far fa-eye",
                            on: { click: _vm.toggleShowPassword }
                          })
                        ]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "form-group row mb-4" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-lg btn-yellow font-weight-bold btn-block",
                        attrs: { type: "submit", id: "login" }
                      },
                      [_vm._v(_vm._s(_vm.$t("LOGIN.SUBMIT")))]
                    )
                  ])
                ]),
                _c("div", { staticClass: "note-text white-text text-center" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("LOGIN.CONTRACT")))])
                ])
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header text-center" }, [
      _c(
        "a",
        {
          staticClass: "logo mx-auto pb-4 mb-1",
          staticStyle: { "background-color": "transparent" },
          attrs: { href: "" }
        },
        [_c("img", { attrs: { src: "img/logo_1.png", alt: "logo" } })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }